import arrow from '../images/icons/ic-arrow.svg';
import contestants from '../images/icons/ic-contestants.svg';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Cookies from 'js-cookie';
import { event as fbqEvent } from '../src/js/lib/fbq';
import { event as plausibleEvent } from '../src/js/lib/plausible';

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('turbo:load', function () {
  console.log('global.js loaded');

  if (Cookies.get('signed_up')) {
    console.log('fbq event: User signed up');

    Cookies.remove('signed_up');

    fbqEvent('track', 'CompleteRegistration');
    plausibleEvent('Signup');
  }

  $('body').on('click', function (e) {
    // open/close main menu
    handleMainMenu(e);
    handleLocalMenus(e);
    handleFilter(e);
  });

  $('.read-more').on('click', function () {
    if (!$(this).hasClass('open')) {
      $('.read-more-truncated').addClass('hidden');
      $('.read-more-content').removeClass('hidden');
      $(this).addClass('open');
      $(this).html('Read less');
    } else {
      $('.read-more-truncated').removeClass('hidden');
      $('.read-more-content').addClass('hidden');
      $(this).removeClass('open');
      $(this).html('Read more');
    }
  });

  $('.close-notification').on('click', function () {
    // close notification
    console.log('close notification:', $(this).data('notification'));
    if (
      $(this).data('notification') !== 'alert' &&
      $(this).data('notification') &&
      'notice'
    ) {
      // Set cookie to hide notification for ever.
      Cookies.set('notification_' + $(this).data('notification'), 'hidden', {
        expires: 365,
      });
    }

    $(this).parent().remove();
  });

  $('#filter input[name="activity"]').on('change', function () {
    console.log('changed', $(this).val());
    $('#filter .activity-icons').each(function (i, obj) {
      $(obj).attr('src', $(obj).data('inactive-icon'));
    });
    var icon = $(this).parent().find('.activity-icons').data('active-icon');
    var elem = $(this).parent().find('.activity-icons');
    console.log(icon);
    elem.attr('src', icon);
  });

  $('.country-filter').on('change', function () {
    if ($(this).val()) {
      $('.city-filter').val('');
      getCities($(this).val());
    } else {
      $('.city-filter').val('');
      $('.city-filter').parent().addClass('hidden');
    }
  });

  $('body').on('click', function (e) {
    if ($(e.target).closest('.search-module-inner-container').length === 0) {
      closeSearch();
    }
  });

  $('#open-search').on('click', function () {
    $('body').addClass('noscroll');
    $('#search-module').removeClass('hidden');

    setTimeout(function () {
      $('#search-module').addClass('open');
    }, 10);
  });

  $('#close-search').on('click', function () {
    closeSearch();
  });

  $('#global-search').on('submit', function (e) {
    e.preventDefault();
  });

  $('#global-search-input').on('input', function () {
    var query = $(this).val();
    // var advancedLink = $("#advanced-search-link")
    //   .attr("href")
    //   .split("?")[0];
    // $("#advanced-search-link").attr("href", advancedLink + "?search=" + query);
    if (query.length > 0) {
      search(query);
    } else {
      closeSearch();
    }
  });

  function handleFilter(e) {
    if ($(e.target).hasClass('toggle-filter')) {
      // clicked on toggle-filter button.
      if ($('#filter').hasClass('hidden')) {
        // filter is closed. Open it
        $('#filter').removeClass('hidden');
        $('.floating-cta').addClass('hidden');
        $('.floating-cta').removeClass('fixed');
      } else {
        // toggle is open. Close it.
        $('#filter').addClass('hidden');
        $('.floating-cta').addClass('fixed');
        $('.floating-cta').removeClass('hidden');
      }
    } else {
      // clicked outside toggle-filter button
      if (!$('#filter').hasClass('hidden')) {
        // menu is open
        if ($(e.target).closest('#filter').length === 0) {
          // clicked outside of menu. Close it
          $('#filter').addClass('hidden');
          $('.floating-cta').addClass('fixed');
          $('.floating-cta').removeClass('hidden');
        }
      }
    }
  }

  function handleMainMenu(e) {
    const targetElement = document.querySelector('#side-navigation-content');

    if ($(e.target).hasClass('toggle-menu')) {
      // clicked on toggle-menu button.
      if ($('#main-menu').hasClass('closed') && window.innerWidth >= 768) {
        // menu is closed. Open it
        $('#main-menu').removeClass('closed');
        $('#menu-icons-container').addClass('hidden');
      } else {
        // menu is open. Close it.
        $('#main-menu').addClass('closed');
        $('#menu-icons-container').removeClass('hidden');
      }

      if ($('#side-navigation').hasClass('hidden') && window.innerWidth < 768) {
        // side-navigation is closed. Open it
        $('#side-navigation').removeClass('hidden');
        $('#side-navigation').addClass('flex');
        $('#side-menu-background').removeClass('hidden');
        $('#side-menu-background').addClass('block');
        disableBodyScroll(targetElement);
      } else {
        // side-navigation is open. Close it.
        $('#side-navigation').removeClass('flex');
        $('#side-navigation').addClass('hidden');
        $('#side-menu-background').removeClass('block');
        $('#side-menu-background').addClass('hidden');
        enableBodyScroll(targetElement);
      }
    } else {
      // clicked outside toggle-menu button
      if (!$('#main-menu').hasClass('closed') && window.innerWidth >= 768) {
        // menu is open
        if ($(e.target).closest('#main-menu').length === 0) {
          // clicked outside of menu. Close it
          $('#main-menu').addClass('closed');
          $('#menu-icons-container').removeClass('hidden');
        }
      }

      if (
        !$('#side-navigation').hasClass('hidden') &&
        window.innerWidth < 768
      ) {
        // menu is open
        if ($(e.target).closest('#side-navigation').length === 0) {
          // clicked outside of menu. Close it
          $('#side-navigation').addClass('hidden');
          $('#side-menu-background').addClass('hidden');
          enableBodyScroll(targetElement);
        }
      }
    }
  }

  function handleLocalMenus(e) {
    if ($(e.target).hasClass('open-local-menu')) {
      // clicked on toggle-menu button.

      var menu = $(e.target)
        .closest('.local-menu-container')
        .find('.local-menu');
      if (menu) {
        // menu for button exists
        if (menu.hasClass('open')) {
          // local menu for clicked trigger is open. Close current menu
          menu.removeClass('open');
          menu.addClass('hidden');
        } else {
          // close all local menus, before opening proper one.
          $('.local-menu.open').removeClass('open');
          $('.local-menu').addClass('hidden');

          menu.addClass('open');
          menu.removeClass('hidden');
        }
      }
    } else {
      // clicked outside toggle-menu button
      if ($('.local-menu.open').length > 0) {
        // some local menu is open
        if ($(e.target).closest('.local-menu-container').length === 0) {
          // clicked outside of menu. Close'em

          $('.local-menu.open').removeClass('open');
          $('.local-menu').addClass('hidden');
        }
      }
    }
  }

  function closeSearch() {
    $('body').removeClass('noscroll');
    $('#search-module').removeClass('open');

    $('#global-search-container').html('');
    $('#global-search-loading').addClass('hidden');
    $('#global-search-input').val('');
    setTimeout(function () {
      $('#search-module').addClass('hidden');
    }, 200);
  }

  function getCities(country) {
    fetch('/api/get-cities', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ country: country }),
      credentials: 'include',
    })
      .then(function (response) {
        if (response.ok) return response.json();
        throw response;
      })
      .then(function (res) {
        console.log('Data:', res);
        $('.city-filter').parent().removeClass('hidden');
        $('.city-filter').children().remove();
        $('.city-filter').append('<option value="">Choose a city</option>');

        for (var i = 0; i < res.length; i++) {
          $('.city-filter').append(
            '<option value="' + res[i] + '">' + res[i] + '</option>'
          );
        }
      })
      .catch(function (e) {
        console.log('something went wrong:', e);
      });
  }

  function search(queryStr) {
    var queryData = { q: queryStr };
    console.log(queryData);

    $('.global-search-outer-container').removeClass('hidden');

    if (queryStr) {
      fetch('/api/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ queryStr: queryStr }),
        credentials: 'include',
      })
        .then(function (response) {
          if (response.ok) return response.json();
          throw response;
        })
        .then(function (res) {
          console.log('Data:', res);
          drawResult(res);

          $('#global-search-loading').addClass('hidden');
        })
        .catch(function (e) {
          $('#global-search-loading').addClass('hidden');
          // e.json()
          // .then(function (error) {
          // 	console.log('error:', error, e);
          // })
          // .catch(function (e) {
          console.log('something went wrong:', e);
          // });
        });
    } else {
      $('#global-search-container').html('');
    }
  }

  function drawResult(data) {
    console.log(data);
    var html = '';
    let segment_types = [];
    for (var i = 0; i < data.length; i++) {
      segment_types = [];
      var fdate = new Date(data[i].from);

      var fd = '0' + fdate.getDate();
      var from =
        fd.slice(-2) + '. ' + fdate.toLocaleString([], { month: 'short' });
      var tdate = new Date(data[i].to);

      var td = '0' + tdate.getDate();
      var to =
        td.slice(-2) + '. ' + tdate.toLocaleString([], { month: 'short' });

      var segments = data[i].segments.length === 1 ? 'segment' : 'segments';
      html +=
        '<a href="' +
        data[i].url +
        '" class="flex justify-between w-full border-b border-border-secondary last:border-none px-4 md:px-6 py-12pt hover:bg-background-quinary">';
      html += '<div class="flex flex-1 items-center min-w-0">';

      data[i].segments.forEach((element) => {
        if (!segment_types.includes(element.segment_type_id)) {
          segment_types.push(element.segment_type_id);
          html +=
            '<span class="flex h-6 w-6 mr-1 text-body-inverted rounded-full justify-center items-center bg-primary shrink-0">';
          html +=
            '<img src="' + element.segment_type_icon_url + '" class="h-4" />';
          html += '</span>';
        }
      });
      html += '<div class="min-w-0 ml-3">';
      html +=
        '<h5 class="truncate m-0 mt-4pt font-text body-text">' +
        data[i].name +
        '</h5>';
      html +=
        '<p class="truncate m-0 mini-text text-text-grey-600">' +
        from +
        ' - ' +
        to +
        ' <span class="hidden medium:inline">| ';
      html += data[i].segments.length + ' ' + segments + '</span></p>';
      html += '</div>';
      html += '</div>';
      html += "<div class='flex items-center'>";
      html += '<span><img src="' + contestants + '" class="w-4 mr-1" /></span>';
      html +=
        '<span class="pr-8 body-text ">' +
        data[i].number_of_challengers +
        '</span>';
      html += '<span><img src="' + arrow + '" class="w-4" /></span>';
      html += '</div>';
      html += '</a>';
    }

    $('#global-search-container').html(html);
  }

  const topMenuBar = document.querySelector('#topMenuBar');

  ScrollTrigger.create({
    trigger: topMenuBar,
    start: 'top top',
    end: 99999,
    toggleClass: { className: 'top-menu-bar-scrolled', targets: topMenuBar },
  });
});
