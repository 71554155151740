export const event = (event = 'track', eventName) => {
  if (window.fbq) {
    if (event && eventName) {
      window.fbq(event, eventName);
    } else {
      console.log('Event is missing:', { event, eventName });
    }
  } else {
    console.log('window.fbq is missing. event not tracked: ', {
      event,
      eventName,
    });
  }
};
