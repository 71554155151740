/* eslint no-console:0 */
// This file is automatically compiled by x, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%# javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('../images', true);
// const imagePath = (name) => images(name, true)

import '../css/application.css';

import 'swiper/css/pagination';
import 'leaflet/dist/leaflet.css';
import 'leaflet-linestring-select/dist/leaflet.lss.min.css';
import 'accordion-js/dist/accordion.min.css';
import 'share-api-polyfill';

import $ from 'jquery/dist/jquery';
window.jQuery = $;
window.$ = $;

import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';

import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import './global.js';
import '../src/js/tooltips.js';

ActiveStorage.start();

// React Rails
// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

// Prevent double mount on page load
ReactRailsUJS._firstTurboLoadSkipped = false;
ReactRailsUJS.handleEvent('turbo:load', () => {
  if (ReactRailsUJS._firstTurboLoadSkipped) ReactRailsUJS.handleMount();
  ReactRailsUJS._firstTurboLoadSkipped = true;
});

// Unmount components and call cleanup functions after Turbo navigations
ReactRailsUJS.handleEvent('turbo:before-render', (e) => {
  console.log('turbo:before-render');
  ReactRailsUJS.unmountComponents(e.target);
  clearAllBodyScrollLocks();

  if (window.unloadFunctions && window.unloadFunctions.length > 0) {
    window.unloadFunctions.forEach((fn) => fn()); // Call all unload functions

    window.unloadFunctions = [];
  }
});
